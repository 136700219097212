<template>
  <div v-if="loading" class="default-loader">
    <v-progress-circular class="vprogress" indeterminate color="gray"></v-progress-circular>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      progressTimeout: null, // 프로그래스 타임아웃
    };
  },
  destroyed() {
    this.clearProgressTimeout();
  },
  methods: {
    // nuxtjs.org/docs/features/loading/
    start() {
      if (this.progressTimeout != null) {
        this.clearProgressTimeout();
      }
      if (!this.loading) {
        this.loading = true;
      }
    },
    finish() {
      if (this.loading && this.progressTimeout == null) {
        this.progressTimeout = setTimeout(() => {
          this.loading = false;
          this.clearProgressTimeout();
        }, 150);
      }
    },
    clearProgressTimeout() {
      clearTimeout(this.progressTimeout);
      this.progressTimeout = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 1rem;
}
.default-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.default-loader::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.default-loader .vprogress {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  z-index: 9999;
  color: #c6cad1;
}
</style>
